:root {
  --glow-primary: #3366AD;
  --glow-text: #969CB3;
  --glow-secondary: #F7F9FD;
  --glow-link: #3A91E9;
}

* {
  font-family: -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
}
.red-star {
  color: red !important;
}

.popup-btm > * {
  margin-top: 20px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/src/assets/icon/arrow.svg") !important;
  background-repeat: no-repeat;
  background-position: right 5px center;
  min-width: 40px;
}

#sidenav-main * {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
}

body {
  background-color: #ffffff;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #212529;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  color: #616161;
}

label {
  color: #212529;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #212529;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #212529
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #212529
}

#sidenav-main {
  z-index: 2;
}

.active.breadcrumb-item {
  color: #212529;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
  border-left: 9px solid var(--glow-primary);
}

.glow-text-primary {
  color: var(--glow-primary);
}

.glow-bg-primary {
  background-color: var(--glow-primary);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--glow-primary);
  font-weight: bold;
}

.icon-shape {
  border-radius: 4px;
}

.table .thead-light th {
  background-color: var(--glow-primary);
  color: var(--glow-secondary);
  text-align: center;
}

.table td {
  text-align: center;
}

.page-item.active .page-link {
  background-color: var(--glow-primary);
  border-radius: 10px;
}

.submit-footer.fixed-bottom {
  height: 70px;

  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;

  padding-right: 50px;
}

.event-list-schedule {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.event-list-schedule .schedule-item {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #39AAEB;
  border-radius: 5px;
  background: #E2F8FF;
  color: #2F80ED;

  min-height: 40px;
}

.schedule-item-container {
  position: relative;
}

.schedule-item-close {
  position: absolute;
  top: -5px;
  right: -5px;
  border: 1px solid #39AAEB;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--glow-primary);
  color: var(--glow-secondary);
}

.schedule-item-close:hover {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.main-content .navbar-top {
  position: relative;
}

.btn {
  border-radius: 4px;
}

.btn-primary {
  color: var(--glow-secondary);
  background-color: var(--glow-primary);
  box-shadow: none;
  border-color: var(--glow-primary);
}

.btn-primary:hover {
  color: var(--glow-secondary);
  background-color: var(--glow-primary);
  box-shadow: none;
  border-color: var(--glow-primary);
}

.btn-outline-primary {
  color: var(--glow-primary);
  border-color: var(--glow-primary);
}

.btn-outline-primary:hover {
  background-color: var(--glow-primary);
}

.btn-secondary:hover {
  color: var(--glow-primary);
  background-color: var(--glow-secondary);
  border-color: var(--glow-primary);
  box-shadow: none;
}

.slick-prev:before,
.slick-next:before {
  color: var(--glow-primary) !important;
}

.btn-secondary {
  color: var(--glow-primary);
  background-color: var(--glow-secondary);
  border-color: var(--glow-primary);
  box-shadow: none;
}

.btn-dark {
  color: var(--glow-secondary);
  background-color: #4E4E4E;
  box-shadow: none;
}

a {
  color: var(--glow-link);
}

a:hover {
  color: var(--glow-primary);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(150, 223, 255, 0.15);
}

.form-control {
  border: 1px solid #CED4DA;
  border-radius: 4px;
}

.custom-file-label::after {
  left: 0;
  right: auto;
  border-left-width: 0;
  border-right: inherit;
}

thead th {
  height: 50px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--glow-primary);
  background-color: #fff;
  border: 1px solid #dee2e6;
  font-size: 13px;
}

.page-item .page-link,
.page-item span {
  border-radius: 0 !important;
  margin: 0;
}

.schedule-nav {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.schedule-nav .nav-item {
  padding-right: 20px;
  padding-left: 20px;
}

.schedule-nav .active {
  border-bottom: 3px solid var(--glow-primary);
  color: var(--glow-primary)
}

.card-event h5 {
  font-size: 20px;
  font-weight: 500;
}

.card-event p {
  font-size: 12px;
}

.card-event .bottom-card {
  width: 100%;
}

.card-event .bottom-card button {
  min-width: 120px;
  height: 38px;
}

.breadcrumb {
  background: #F8F9FA;
}

.card-event.card-worship .card-img-top {
  padding-left: 20%;
  padding-right: 20%;

  background-color: #003366;
}

.card-event img {
  width: 100%;
}

.has-search {
  position: relative;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 1;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  right: 5%;
  top: 33%;
}

.time-badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 10px;

  background: #E3E3E3;
  border-radius: 4px;
}

.img-content-slider {
  position: relative;

  width: 67px;
  height: 67px;

  cursor: pointer;
}

.img-content-slider .edit-fill {
  position: absolute;

  width: 17px;
  height: 17px;

  bottom: 0;
  right: 0;
  border: 1px solid rgba(244, 244, 244, 1);
  background: #ffffff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.img-content-slider img {
  width: 100%;
  border-radius: 50%;
}

.header-ticket-info {
  background-color: var(--glow-primary);
  color: #ffffff;
}

.ticket-info h4 {
  font-size: 14px;
}

.img-rounded-item {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #C4C4C4;

  border: 1px solid rgba(244, 244, 244, 1);
  border-radius: 50%;

  width: 45px;
  height: 45px;
}

.ticket-info p,
.ticket-info ul li {
  font-size: 12px;
}

.ticket-info ul {
  padding-left: 15px;
}

.btn-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial,
    sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  cursor: pointer;
}

.btn-link-danger {
  color: rgba(255, 114, 143, 1);
}

.btn-link-danger:hover {
  color: rgba(255, 114, 143, 2);
}

.change-type {
  cursor: pointer;
}

.text-align-start {
  text-align: start !important;
}

.max-width-200 {
  max-width: 150px !important;
  white-space: break-spaces !important;
}

.img-content-slider {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #C4C4C4;
}

.banner {
  position: relative
}

.banner .del-card {
  position: absolute;

  top: -18px;
  right: -10px;
  z-index: 2;

  cursor: pointer;
}

.drop-select {
  min-width: 200px;
}

.col-xl-2c4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.modal-content {
  box-shadow: none !important;
}

table td, table th {
  text-align: left !important;
}

.form-control.form-search {
  border-color: var(--kunjung-primary)!important;
  background-image: url('/src/assets/icon/mdi_magnify.svg')!important;
  background-position: right calc(.375em + .1875rem) center!important;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)!important;
  background-repeat: no-repeat!important;
  padding-right: calc(1.5em + .75rem)!important;
}

.custom-file-label::after {
  content: "Upload File" !important;
  color: #6C757D;;
  border-radius: 0;
  background-color: #E9ECEF;

  z-index: 0;
}

.custom-file-input {
  z-index: 0;
}

.custom-file-label {
  z-index: 0;
}

.custom-file-label .file-name {
  text-overflow: ellipsis;

  margin-left: 100px;
  white-space: nowrap;
}

.glow-collapse .nav-link.active:before {
  border-left: none !important;
}

.glow-side-icon {
  font-size: .9375rem !important;
  line-height: 1.5rem;
  min-width: 2rem;
}

.glow-collapse-link, .toggler-collapse {
  cursor: pointer;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.glow-collapse-link::after,  .toggler-collapse::after {
  content: url('https://api.iconify.design/bx/caret-down.svg');

  display: inline-block;
  margin-left: auto;
  transition: all .15s ease;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  transform: rotate(270deg);

  position: absolute;
  right: 10px;
}

.glow-collapse-link.glow-collapse-active::after,  .toggler-collapse.glow-collapse-active::after {
  transform: rotate(0deg);
}

.glow-collapse-link.active::after {
  content: url('https://api.iconify.design/bx/caret-down.svg?color=%231968b3');
}

@media (min-width: 768px) {
  .submit-footer.fixed-bottom {
    left: 250px;
  }

  #navbar-main {
    box-shadow: 0px -2px 24px rgba(171, 171, 171, 0.25);
  }

  .main-content .navbar-top {
    position: relative;
    margin-bottom: 40px;
  }

  .col-xl-2c4 {
    flex: 0 0 20%;
    max-width: 20%;
  }


}